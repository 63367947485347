.wrapper {
    width: 100%;
    height: 982px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #101548;

    @media(max-width: "700px") {
        height: 700px;
    }

    .twitter {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;

        a {
            cursor: pointer;
            text-decoration: none;
            display: flex;
            justify-content: center;

            svg {
                &:hover {
                    transform: scale(1.05);
                }
            }

            .logo {
                width: 110px;
                height: 110px;
                border: 1px solid #6211B1;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FC49EF;
                margin-bottom: 50px;
            }

            .text {
                font-size: 27px;
                line-height: 32px;
                color: rgba(98, 17, 177, 1);
            }
        }
    }
}
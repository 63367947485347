body.light {
  // Global
  --body-bg-1: #0C103B;

}

body.dark {
  // Misc
  --body-bg-1: #0C103B;

  .link {
    text-decoration: none !important;
    color: inherit;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text);
  }
}
.wrapper {
    width: 100%;
    height: 982px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media(max-width: "700px") {
        height: 802px;
    }

    .navbar {
        position: absolute;
        left: 20%;
        top: 25px;

        @media(max-width: "1200px") {
            left: 15%;
        }

        @media(max-width: "650px") {
            left: 5%;
            top: 10px;
        }

        img {
            width: 170px;

            @media(max-width: "650px") {
                width: 125px;
            }
        }
    }

    .connectButton {
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(102.97deg, #AD21B4 0%, #4B3EC7 100%);
        border-bottom-left-radius: 80px;
        width: 360px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: white;
        cursor: pointer;
        // text-align: none;
        text-decoration: none;

        @media(max-width: "650px") {
            width: 175px;
            height: 50px;
            font-size: 14px;
        }

        &:hover {
            background: #f78235;
        }
    }



    .rock {
        height: 610px;
        position: absolute;
        top: 693px;
        right: 0px;
        z-index: 10;

        @media(max-width: "700px") {
            top: 675px;
            height: 250px;
        }
    }

    .intro {
        display: flex;
        align-items: center;
        justify-content: center;


        @media(max-width: "700px") {
            flex-direction: column;
            margin-top: -175px;
        }

        img {
            width: 400px;

            @media(max-width: "1150px") {
                width: 300px;
            }

            @media(max-width: "900px") {
                width: 250px;
            }
        }

        .header {
            font-weight: 300;
            font-size: 115.576px;
            line-height: 106px;
            background: linear-gradient(263.59deg, #6211B1 0%, #FC49EF 99.38%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-left: 80px;
            margin-top: 60px;

            @media(max-width: "1150px") {
                font-size: 80px;
                line-height: 80px;
            }

            @media(max-width: "900px") {
                font-size: 60px;
                line-height: 60px;
            }

            @media(max-width: "700px") {
                margin-left: 0px;
                margin-top: 10px;
                font-size: 50px;
                line-height: 50px;
            }

            span {
                font-weight: 700;
            }

            .button {
                border-radius: 16px;
                width: 350px;
                height: 70px;
                border: 1px solid #BD32D6;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 40px;
                font-weight: 400;
                font-size: 22px;
                cursor: pointer;
                -webkit-text-fill-color: white;

                &:hover {
                    background-color: #F78235;
                }

                @media(max-width: "900px") {
                    width: 200px;
                    height: 60px;
                    margin-top: 20px;
                }

                // .border {
                //     width: 356px;
                //     height: 76px;
                //     border-radius: 50px;
                //     background-color: #101548;

                //     @media(max-width: "700px") {
                //         width: 276px;
                //         height: 56px;
                //     }

                //     .mint {
                //         display: flex;
                //         align-items: center;
                //         justify-content: center;
                //         background: linear-gradient(270deg, #6211B1 0%, #FC49EF 100%);
                //         border-radius: 50px;
                //         height: 100%;
                //         font-weight: 700;
                //         font-size: 18px;
                //         line-height: 21px;
                //         text-align: center;
                //         color: white;

                //         &:hover {
                //             background: linear-gradient(90deg, #6211B1 0%, #FC49EF 100%);
                //         }
                //     }
                // }
            }
        }
    }
}
.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #101548;
    height: 1052px;
    width: 100%;
    padding: 100px 0px;
    color: white;

    @media(max-width: "1200px") {
        height: auto;
    }

    @media(max-width: "1000px") {
        // height: 750px;
    }

    @media(max-width: "700px") {
        // height: 1110px;
    }

    .title {
        font-weight: 900;
        font-size: 28px;
        line-height: 33px;
        letter-spacing: 0.44em;
        margin-bottom: 80px;
    }


    .utilities {
        display: flex;
        align-items: center;

        img {
            width: 98%;
        }
    }

    .mobile{
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
    }

    .buttons {
        border-radius: 50px;
        width: 360px;
        height: 45px;
        border: 1px solid linear-gradient(rgba(98, 17, 177, 0.96), rgba(252, 73, 239, 0.96));
        background: linear-gradient(to left, rgba(98, 17, 177, 0.96), rgba(252, 73, 239, 0.96));
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        cursor: pointer;

        @media(max-width: "700px") {
            width: 280px;
        }

        .border {
            width: 356px;
            height: 41px;
            border-radius: 50px;
            background-color: #101548;
            position: relative;

            @media(max-width: "700px") {
                width: 276px;
            }

            .mint {
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(270deg, #6211B1 0%, #FC49EF 100%);
                border-radius: 50px;
                height: 100%;
                font-weight: 700;
                font-size: 18px;
                line-height: 21px;
                text-align: center;

                &:hover {
                    background: linear-gradient(90deg, #6211B1 0%, #FC49EF 100%);
                }
            }

            .count{
                position: absolute;
                right: 15px;
                top: 12px;

            }
        }
    }

}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}
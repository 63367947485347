.wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;

    .title {
        margin-top: 100px;

        @media(max-width: 450px) {
            img {
                width: 280px;
            }
        }
    }

    .collection {
        width: 100%;
        margin: 50px 0px;
        position: relative;
        transition: all 0.2s;



        .row1 {
            display: flex;
            transition: 1s all;
            position: relative;
            flex-wrap: wrap;
            width: 100%;

            .forLocation {
                width: 25%;
                opacity: 0;
            }
        }

        .row2 {
            display: flex;
            transition: 1s all;
            position: relative;
            flex-wrap: wrap;
            width: 100%;

            .forLocation {
                width: 50%;
                opacity: 0;
            }
        }
    }

    .parts {
        max-width: 1000px;
        width: 100%;
        height: 22px;
        display: flex;
        align-items: center;
        margin-bottom: 100px;
        padding: 0px 25px;


        .off {
            height: 6px;
            border-radius: 10px;
            background-color: #192076;
            width: 100%;
            margin: 0px 5px;
        }

        .on {
            height: 6px;
            border-radius: 10px;
            width: 100%;
            background: #FC49EF;
            margin: 0px 5px;
        }

        .next {
            // position: absolute;
            // top: 50%;
            // right: 25px;
            // transform: rotateY(-50%);
            transition: all 0.2s;
            cursor: pointer;
            opacity: 0.8;
            z-index: 1;
            color: #FC49EF;
            display: flex;
            align-items: center;
            height: 24px;

            @media(max-width: 700px) {
                // width: 20px;
                // height: 20px;
                // right: 5px;
            }

            &:hover {
                transition: all 0.3s;
                transform: scale(1.3);
                opacity: 1;
            }
        }

        .back {
            // position: absolute;
            // top: 50%;
            // left: 25px;
            // transform: rotateY(-50%);
            transition: all 0.2s;
            cursor: pointer;
            opacity: 0.8;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FC49EF;

            @media(max-width: 700px) {
                width: 20px;
                height: 20px;
                left: 5px;
            }

            &:hover {
                transition: all 0.3s;
                transform: scale(1.3);
                opacity: 1;
            }
        }
    }
}